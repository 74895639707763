<template>
  <div class="exception">
    <div class="imgBlock">
      <div class="imgEle" :style="{ backgroundImage: `url(${config[type].img})` }"></div>
    </div>
    <div class="content">
      <h1>{{ config[type].title }}</h1>
      <div class="desc">{{ config[type].desc }}</div>
      <div class="desc"><span style="font-size: 35px;font-weight: bold;">{{ this.timerCount }}</span>&nbsp;秒后自动返回登录页</div>
      <div class="actions">
        <el-button size="mini" type="primary" @click="handleToHome">返回登录</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import types from './type'
import TokenCache from '@/utils/cache/TokenCache'
import OperatorCache from '@/utils/cache/OperatorCache'

export default {
  name: 'ExceptionPage',
  props: {
    type: {
      type: String,
      default: '404'
    }
  },
  data () {
    return {
      config: types,
      timerCount: 5
    }
  },
  watch: {
    timerCount: {
      handler (value) {
        if (value === 0) {
          this.handleToHome()
        }
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--
          }, 1000)
        }
      },
      immediate: true // This ensures the watcher is triggered upon creation
    }
  },
  methods: {
    handleToHome () {
      TokenCache.deleteToken()
      OperatorCache.clear()
      this.$router.push({ path: '/Home' })
    }
  }
}
</script>
<style lang="scss" scoped>
.exception {
  display: flex;
  align-items: center;
  height: 80%;
  min-height: 500px;

  .imgBlock {
    flex: 0 0 62.5%;
    width: 62.5%;
    padding-right: 152px;
    zoom: 1;

    &::before,
    &::after {
      content: ' ';
      display: table;
    }

    &::after {
      clear: both;
      height: 0;
      font-size: 0;
      visibility: hidden;
    }
  }

  .imgEle {
    float: right;
    width: 100%;
    max-width: 430px;
    height: 360px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
  }

  .content {
    flex: auto;

    h1 {
      margin-bottom: 24px;
      color: #434e59;
      font-weight: 600;
      font-size: 72px;
      line-height: 72px;
    }

    .desc {
      margin-bottom: 16px;
      color: var(--el-text-color-secondary);
      font-size: 20px;
      line-height: 28px;
    }

    .actions {
      button:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}

@media screen and (max-width: var(--el-screen-xl)) {
  .exception {
    .imgBlock {
      padding-right: 88px;
    }
  }
}

@media screen and (max-width: var(--el-screen-sm)) {
  .exception {
    display: block;
    text-align: center;

    .imgBlock {
      margin: 0 auto 24px;
      padding-right: 0;
    }
  }
}

@media screen and (max-width: var()--el-screen-xs) {
  .exception {
    .imgBlock {
      margin-bottom: -24px;
      overflow: hidden;
    }
  }
}
</style>
